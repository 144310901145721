import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Table from '../components/Table'

export default function biocompatibilityJustificationSummaryTemplate({
  data,
  pageContext,
}) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath}>
      <p>
        [The following table will be used to determine the category, contact,
        duration and tests associated with the product. All “Biological Effects”
        with an X and an O need to be tested. Delete prior to uploading]
      </p>
      <div style={{ height: '570px', width: '600px', background: 'white' }}>
        <StaticImage
          src="../images/Biocompatibility Justification Summary/BiocompatibilityCategorization.png"
          alt="BiocompatibilityCategorization"
          width={600}
        />
      </div>
      <section className="section sectionbody">
        <h5 className="title is-5">
          5 – Categorization by Nature of Body Contact
        </h5>
        <p>
          The {productName} has been assessed to the following nature and
          duration of body contact:
        </p>
        <p>
          Category: {frontmatter.category}
          Contact Type: {frontmatter.contactType}
          Contact Duration: {frontmatter.contactDuration}
        </p>
      </section>
      <section className="section sectionbody">
        <h5 className="title is-5">6.1 – Material Characterization:</h5>
        {frontmatter.contactsPatient === 'true' ? (
          <p>
            During the use of {productName}, the following materials may come
            into contact with the patient and/or user.
            <Table table={frontmatter.contactMaterialsTable} />
          </p>
        ) : (
          <p>
            The {productName} is not meant for direct patient contact during
            procedure or use. The following materials may come into contact with
            the patient and/or user if used incorrectly.
          </p>
        )}
      </section>
      <section className="section sectionbody">
        <h5 className="title is-5">6.2 - Biological Evaluation Tests:</h5>
        <p>
          The risks associated with [Product Name] biocompatibility have been
          addressed in the [Product Name] Risk Management File LINK.
        </p>
        <p>
          The biological evaluations tests below correspond with the
          “Categorization by Nature of Body Contact” as specified in Table A.1
          of ISO 10993-1:2009. The results for the following tests will be found
          on the respective page number and will consist of either existing data
          that has identified that the risks are acceptable and/or test reports
          including descriptive evidence and assessment of their findings.
        </p>
        <p>The Product Name will be assessed under the following tests:</p>
        <Table table={frontmatter.biocompatibilityTestsTable} />
      </section>

      <section className="section sectionbody">
        <h5 className="title is-5">Determination:</h5>
        <p>
          After reviewing the biological evaluation test reports, the risks
          associated with {productName} biocompatibility have been evaluated and
          determined negligible in the {productName} Risk Management File LINK
          since the products benefit outweighs any potential risk.
        </p>
        {frontmatter.contactsPatient === 'true' ? (
          <p>
            The product is not intended to come in contact with the patient.
            Materials have been selected such that if the device comes into
            unintended contact with the patient, then no adverse reactions
            occur.
          </p>
        ) : (
          <p>
            {`All patient-contacting materials used in the construction of the device have been shown to be biocompatible to ISO 10993 for [Contact duration, contact type, contact time] (i.e limited skin contact for >24 hours, etc.)`}
          </p>
        )}
      </section>
      <section className="section sectionbody">
        <h5 className="title is-5">Qualification of Responsible Evaluators:</h5>
        <table>
          <tr>
            <td>
              Evaluator: {frontmatter.evaluator[0].name},{' '}
              {frontmatter.evaluator[0].position},
              {frontmatter.evaluator[0].yearStarted} –
              {frontmatter.evaluator[0].jobDescription}
            </td>
            <td>
              Action Completion Date:{' '}
              {frontmatter.evaluator[0].actionCompletionDate}
            </td>
          </tr>
          <tr>
            <td>
              Reviewer: {frontmatter.reviewer[0].name},{' '}
              {frontmatter.reviewer[0].position},
              {frontmatter.reviewer[0].yearStarted} –
              {frontmatter.reviewer[0].jobDescription}
            </td>
            <td>
              Action Completion Date:{' '}
              {frontmatter.reviewer[0].actionCompletionDate}
            </td>
          </tr>
          <tr>
            <td>
              Reviewer: {frontmatter.approval[0].name},{' '}
              {frontmatter.approval[0].position},
              {frontmatter.approval[0].yearStarted} –
              {frontmatter.approval[0].jobDescription}
            </td>
            <td>
              Action Completion Date:{' '}
              {frontmatter.approval[0].actionCompletionDate}
            </td>
          </tr>
        </table>
        {frontmatter.cytotoxicityStudy && (
          <div>
            <h1 className="title is-1">Cytotoxicity Study</h1>
            <p>link goes here: {frontmatter.cytotoxicityStudy}</p>
          </div>
        )}
        {frontmatter.sensitizationStudy && (
          <div>
            <h1 className="title is-1">Sensitization Study</h1>
            <p>{}</p>
          </div>
        )}
        {frontmatter.irritationStudy && (
          <div>
            <h1 className="title is-1">Irritation Study</h1>
            <p>{}</p>
          </div>
        )}
        {frontmatter.systemicToxicityStudy && (
          <div>
            <h1 className="title is-1">Systemic Toxicity Study</h1>
            <p>{}</p>
          </div>
        )}
        {frontmatter.subchronicToxicityStudy && (
          <div>
            <h1 className="title is-1">Subchronic Toxicity Study</h1>
            <p>{}</p>
          </div>
        )}
        {frontmatter.genotoxicityStudy && (
          <div>
            <h1 className="title is-1">Genotoxicity Study</h1>
            <p>{}</p>
          </div>
        )}
        {frontmatter.implantationStudy && (
          <div>
            <h1 className="title is-1">Implantation Study</h1>
            <p>{}</p>
          </div>
        )}
        {frontmatter.hemocompatibilityStudy && (
          <div>
            <h1 className="title is-1">Hemocompatibility Study</h1>
            <p>{}</p>
          </div>
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryBiocompatibilityJustificationSummary($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      fields {
        filePath
      }
      frontmatter {
        templateName

        contactsPatient

        category
        contactType
        contactDuration

        contactMaterialsTable {
          partDescription
          MSDSKTDOCID
          material
        }
        biocompatibilityTestsTable {
          biologicalTest
          results
          standard
          testKTNumber
          pageNumber
        }
        evaluator {
          name
          position
          yearStarted
          jobDescription
          actionCompletionDate
        }
        reviewer {
          name
          position
          yearStarted
          jobDescription
          actionCompletionDate
        }
        approval {
          name
          position
          yearStarted
          jobDescription
          actionCompletionDate
        }
        cytotoxicityStudy
        sensitizationStudy
        irritationStudy
        systemicToxicityStudy
        subchronicToxicityStudy
        genotoxicityStudy
        implantationStudy
        hemocompatibilityStudy
      }
    }
  }
`
